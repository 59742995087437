import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_SENTRY_ENVIRONMENT } from '$env/static/public';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
  dsn: 'https://c98d946d5783e1004c97c3d42bca2eac@o30383.ingest.us.sentry.io/4507606660939776',
  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1,
  integrations: [Sentry.replayIntegration()],
  enabled: PUBLIC_SENTRY_ENVIRONMENT === 'production'
});
// temp
export const handleError = Sentry.handleErrorWithSentry();
